import request from '@/utils/request'
//分页查询数据
export function listPage(data) {
  return request({
    url: '/dx-message-info/list',
    method: 'post',
   data
  })
}
//新增群发服务
export function addList(data) {
  return request({
    url: '/dx-message-info/add',
    method: 'post',
    data
  })
}
//获取微信群
export function wechatGroup(data) {
  return request({
    url: '/ipadFirstPage/wechatGroup',
    method: 'post',
    data
  })
}
//消息内容概览
export function statistics(query) {
    return request({
      url: '/dx-message-info/statistics',
      method: 'get',
      params: query
    })
  }
//删除
export function deletePage(query) {
  return request({
    url: '/dx-message-info/delete',
    method: 'get',
    params: query
  })
}
//详情
export function infoone(query) {
  return request({
    url: '/dx-message-info/one',
    method: 'get',
    params: query
  })
}
//开启指令
export function runstart(query) {
  return request({
    url: '/dx-message-info/start',
    method: 'get',
    params: query
  })
}
//暂停指令
export function dxstop(query) {
  return request({
    url: '/dx-message-info/stop',
    method: 'get',
    params: query
  })
}
//修改群发服务
export function messUpdate(data) {
  return request({
    url: '/dx-message-info/edit',
    method: 'post',
    data
  })
}
//提交
export function dxsubmit(query) {
  return request({
    url: '/dx-message-info/submit',
    method: 'get',
    params: query
  })
}
//消息的用户查看: orderParam 0 查看次数 升序 1 查看次数 降序 ，2 查看时间 升序 3 查看时间降序
export function userview(data) {
  return request({
    url: '/dx-message-info/user/view',
    method: 'post',
    data
  })
}
//消息的群内容： orderParam 0 成员 升序 1 成员降序 ，2 发送时间 升序 3 发送时间降序 4 查看时间升序 5 查看时间降序
export function wechatGroupList(data) {
  return request({
    url: '/dx-message-info/wechatGroupList',
    method: 'post',
    data
  })
}
//导出数据
export function dxexport(data) {
    return request({
        url: '/dx-message-info/export',
        method: 'post',
        responseType: "blob",
        data
    })
}
//复制
export function dxcopy(query) {
  return request({
    url: '/dx-message-info/copy',
    method: 'get',
    params: query
  })
}
