<template>
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" @click="handleClick" class="text"></div>
  </div>
</template>
  
  <script>
import E from 'wangeditor'

import { apiUpload } from '@/api/demand'
import AlertMenu from './AlertMenu'
import AttachMenu from './attachment'

export default {
  name: 'EditorBar',
  data() {
    return {
      info: null,
      editor: '',
      isChange: false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info = null
      }
    },
    value() {
      if (!this.isChange) {
        this.editor.txt.html(this.value)
      }
      this.isChange = false
    }
  },
  mounted() {
    this.seteditor()
  },
  methods: {
    handleClick(event){
      const target = event.target;
    if (target.tagName === 'A') {
      const href = target.getAttribute('href');
      if (href) {
        window.open(href, '_blank'); // 在新标签页中打开链接
      }
    }
      
    },
    seteditor() {
  
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)

      this.editor.config.uploadImgHeaders = {
        token: sessionStorage.getItem('token')
      } // 自定义 header
      this.editor.config.uploadFileName = 'image' // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 12 * 1024 * 1024 // 将图片大小限制为 12M
      this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      // 4.自定义控制上传过程--上传到阿里云
      this.editor.config.customUploadImg = async (files, insert) => {
        
        
        const fileData = new FormData()
        fileData.append('file', files[0])
   
        const res = await apiUpload(fileData)
        if (res.resultCode == 200) {
         
          insert(res.data)
        }
      }
      this.editor.config.whitelist = {
        a: ['href', 'target', 'name'] // 允许 a 标签的 href、target 和 name 属性
      }
      this.editor.config.showFullScreen = true
      // 配置菜单
      this.editor.config.uploadFileName = 'myFileName'
      // 配置请求的参数名（默认为 wangeditor-uploaded-image）
      this.editor.config.uploadImgFieldName = 'yourFieldName'
      this.editor.config.menus = [
        'bold', // 加粗
        'italic', // 斜体
        'underline', // 下划线
        'foreColor', // 文字颜色
        'emoticon',
        'image', // 插入图片

        'video'
      ]
      // this.editor.menus.extend('alertMenu', AlertMenu) // 配置扩展的菜单
      // this.editor.config.menus = this.editor.config.menus.concat('alertMenu')
      this.editor.menus.extend('attachMenu', AttachMenu) // 配置扩展的菜单
      this.editor.config.menus = this.editor.config.menus.concat('attachMenu')

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
          console.log(xhr, editor, result)
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          //
          // let imgUrl = result.data;
          // insertImg(imgUrl)
          console.log(xhr, editor, result)
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
          console.log(xhr, editor)
        },
        error: (xhr, editor) => {
          console.log(editor)
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
         
          var url = result.data
          insertImg(url) //将内容插入到富文本中
        }
      }
      //下方本地上传视频方法同理
      this.editor.config.customUploadVideo = async (resultFiles, insertVideoFn) =>{
        const formData = new FormData()
        formData.append('file', resultFiles[0])
     
        const res = await apiUpload(formData)
        if (res.resultCode == 200) {
          insertVideoFn(res.data, "video.mp4", res.data);
          // insert(res.data)
        }
      }
     

      this.editor.config.onchange = (html) => {
        this.isChange = true
        this.info = html // 绑定当前逐渐地值
        this.$emit('change', this.info) // 将内容同步到父组件中
      }

      // 创建富文本编辑器
      this.editor.create()
    }
  }
}
</script>
  
  <style scoped lang="less">

.editor {
  width: 100%;
  height: 100%;
  .text {
    border: 1px solid #ccc;
   
  }
}
.toolbar {
  border: 1px solid #ccc;
  margin-bottom: -1px;
}

::v-deep .w-e-toolbar {
  position: static;
  z-index: 999;
}
.w-e-text-container {
  position: static;
  z-index: 999;
}

::v-deep .w-e-text {
  min-height: 434px;
}
::v-deep .w-e-toolbar .w-e-active:hover i {
  color: #999999 !important;
}
::v-deep .w-e-toolbar .w-e-active i {
  color: #999999 !important;
 
}

::v-deep .w-e-menu {
  z-index: 2 !important; /* 菜单层级 */
}

::v-deep .w-e-text-container {
  z-index: 1 !important; /* 编辑区域层级 */
}

::v-deep .w-e-toolbar {
  z-index: 2 !important; /* 工具栏层级 */
}
</style>
  