<template>
  <div class="ceate_content">
    <div class="ceate_center">
      <div class="top_step">
        <el-steps :active="active" align-center>
          <el-step title="创建内容"></el-step>
          <el-step title="选择群聊"></el-step>
          <el-step title="提交成功"></el-step>
        </el-steps>
      </div>
      <div class="auto_form" v-show="active == 1">
        <createform :info="info" ref="contentRef"></createform>
      </div>
      <div v-show="active == 3">
        <successfully ref="successRef" :massId="massId"></successfully>
      </div>
      <div v-show="active == 2">
        <groupchat :info="info" ref="groupchatRef"></groupchat>
      </div>
    </div>
    <div class="bottom_page" v-show="active != 3">
      <el-button type="primary" @click="backMass">取消</el-button>
      <el-button type="primary" plain @click="submitForm">保存</el-button>
      <el-button type="warning" @click="upStep" v-show="active == 2" plain>上一步</el-button>
      <el-button type="primary" v-if="active == 1" @click="nextStep">下一步</el-button>
      <el-button type="primary" v-if="active == 2" @click="simClick">提交</el-button>
    </div>
  </div>
</template>
      
<script>
import { addList, messUpdate, infoone, dxsubmit } from '@/api/masssend.js'
import createform from './createform.vue'
import successfully from './successfully.vue'
import groupchat from './groupchat.vue'
export default {
  name: 'CreateContent',
  components: { createform, successfully, groupchat },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      active: 1,
      massId: '',
      info: {},
      isshake:false,
    }
  },

  created() {
    if (this.id) {
      this.getdetais()
    }
  },
  methods: {
    async getdetais() {
      const res = await infoone({ id: this.id })
      if (res.resultCode == 200) {
        this.info = res.data
      }
    },
    nextStep() {
      if(!this.$refs.contentRef.form.context){
        this.$message.warning('请输入消息内容!')
        return
      }
      this.active = 2
    },
    upStep() {
      this.active = 1
    },
    async simClick() {
      if(this.isshake){
        return
      }
      this.isshake=true
      const merged = Object.assign({}, this.$refs.contentRef.form, {
        groupName: this.$refs.groupchatRef.eleList
      })
      if(this.id){
        messUpdate(merged).then((response) => {
          if (response.resultCode === 200) {
            this.massId =this.id
            this.dxsubmit()
          }else{
            this.isshake=false
          }
        })
       
      }else{
      
      const res = await addList(merged)
      if (res.resultCode == 200) {
        this.massId = res.message
        this.dxsubmit()
      }else{
        this.isshake=false
      }
      }
     
    },
    async dxsubmit() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      const res = await dxsubmit({ id: this.id|| this.massId  })
      if (res.resultCode == 200) {
        loading.close();
        this.$refs.successRef.getdetais()
        this.isshake=false
        this.active = 3
      }else{
        loading.close();
        this.isshake=false
      }
    },
    backMass() {
      this.$router.go(-1)
    },
    //保存
    async submitForm() {
      if(this.isshake){
        return
      }
      this.isshake=true
    
      if(!this.$refs.contentRef.form.context){
        this.isshake=false
        this.$message.warning('请输入消息内容!')
        return
      }
      const merged = Object.assign({}, this.$refs.contentRef.form, {
        groupName: this.$refs.groupchatRef.eleList
      })
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      if (!merged.id) {
        // 没有id为新增
        addList(merged).then((response) => {
          if (response.resultCode === 200) {
            this.$message.success('保存成功!')
             this.isshake=false
            this.$router.go(-1)
          }else{
            this.isshake=false
          }
        })
        loading.close();
      } else {
        messUpdate(merged).then((response) => {
          if (response.resultCode === 200) {
            this.$message.success('修改成功')
            this.$router.go(-1)
            this.isshake=false
          }else{
            this.isshake=false
          }
        })
        loading.close();
      }
     
    }
  }
}
</script>
      
<style lang="less" scoped>
.ceate_content {
  background: #fff;
  height: calc(100vh - 100px);
  .top_step {
    padding: 20px 0px;
    margin: 0px 200px;
  }
  .ceate_center {
    height: calc(100vh - 180px);
    .auto_form {
      height: calc(100vh - 280px);
      overflow: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
    }
  }
  .bottom_page {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #c4c4c4;
  }
}
</style>
      