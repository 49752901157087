<template>
  <div class="case_data">
    <div class="case_data_over">
      <div class="case_data_dialog" ref="pdfContent">
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="left">
              <div class="case_table_li_left">信息类别</div>
              <div class="case_table_li_right">{{ caseInfo.type }}</div>
            </div>
            <div class="right">
              <div class="case_table_li_left">发送时间</div>
              <div class="case_table_li_right">
                {{ caseInfo.sendStartTime }}
                <span v-if="caseInfo.sendEndTime">-{{ caseInfo.sendEndTime }}</span>
              </div>
            </div>
          </div>
          <div class="case_table_li">
            <div class="lefts">
              <div class="case_table_li_left_two">
                <span>信息内容</span>
              </div>
              <div class="case_table_li_rights" v-html="caseInfo.context"></div>
            </div>
            <div class="right">
              <div class="case_table_li_left_two">发送群聊</div>
              <div class="case_table_li_right">
                <span>共选择发送</span>
                <span style="color: #448aff">{{ total }}</span>
                <span>个群聊</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_succ">
      <img class="icon_succ" src="../../../assets/image/succs.png" alt="" />
      <div class="succ_right">
        <div class="bottom_name">
          <span>您填写的内容已提交成功</span>
        </div>
        <div>
          <img @click="backMass" src="../../../assets/image/backgo.png" alt="" />
          <img @click="kaistart" src="../../../assets/image/kaiqi.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { infoone, runstart } from '@/api/masssend.js'
export default {
  name: 'serviceinfo',
  props: {
    massId: {
      type: String
    }
  },
  data() {
    return {
      activeName: 'first',
      total: 0,
      caseInfo: {} //案例详情数据
    }
  },
  computed: {},
  created() {
    this.getdetais()
  },
  methods: {
    async getdetais() {
      const res = await infoone({ id: this.massId })
      if (res.resultCode == 200) {
        this.caseInfo = res.data
        if (this.caseInfo.groupName) {
          this.total = this.caseInfo.groupName.length
        }
      }
    },
    // 点击
    backMass() {
      this.$router.go(-1)
    },
    kaistart() {
      this.$confirm('确认是否开启指令！')
        .then(async () => {
          const res = await runstart({ id: this.massId })
          if (res.resultCode == 200) {
            this.$message.success('开启成功!')
            this.$router.go(-1)
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch(() => {})
    }
  }
}
</script>
      
      <style lang="less" scoped>
.case_data_dialog {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px;

  .download {
    display: flex;
    margin-left: 10px;
    align-items: center;
    box-sizing: border-box;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid #4e93fb;
    color: #4e93fb;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .case_tabel .case_table_li:nth-last-child(1) {
    border-bottom: 1px solid #dcdee0;
  }

  .case_tabel {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
    .img_radio {
      width: 20px;
      height: 20px;
    }
    .case_table_li {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      border-right: 1px solid #dcdee0;
      border-top: 1px solid #dcdee0;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }
      .lefts {
        width: 50%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
      }

      .right {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }

      .case_table_li_left {
        width: 120px;
        background: #fafafa;
        display: flex;
        font-weight: 550;
        align-items: center;
        border-right: 1px solid #dcdee0;
        justify-content: center;
        border-left: 1px solid #dcdee0;
      }
      .case_table_li_left_two {
        width: 120px;
        background: #fafafa;
        font-weight: 550;
        line-height: 260px;
        border-right: 1px solid #dcdee0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-left: 1px solid #dcdee0;
        .po_bottom {
          position: relative;
          top: -8px;
        }
        .po_top {
          position: relative;
          top: 8px;
        }
      }
      .case_table_li_right {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding: 0 12px 0px 30px;
        align-items: center;
        line-height: 2em;
        height: 80px;
        overflow: auto;
        flex-shrink: 1;
        .customerHead {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .img {
          display: flex;
          align-items: center;
          img {
            width: 100px;
            height: 64px;
            border-radius: 8px;
            margin-right: 16px;
          }
        }
      }

      .case_table_li_rights {
        flex: 1;
        box-sizing: border-box;
        padding:10px 12px 0px 20px;
        height: 260px;
        overflow: auto;
        flex-shrink: 1;
        .customerHead {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }

        .img {
          display: flex;
          align-items: center;
          img {
            width: 100px;
            height: 64px;
            border-radius: 8px;
            margin-right: 16px;
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
      }
    }
  }
}
::v-deep .case_table_li_rights p {
  display: inline-block;
  padding: 0px;
  margin: 10px;
}
::v-deep .case_table_li_rights video {
  width: 300px;
  height: 200px;
}

.case_data {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px 100px;
  box-sizing: border-box;
  background-color: #fff;
  .bottom_succ {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .icon_succ {
      width: 210px;
      height: 210px;
    }
    .succ_right {
      margin-left: 10px;
      height: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .bottom_name {
        font-size: 28px;
        margin-bottom: 20px;
      }
      img {
        margin-top: 10px;
        width: 180px;
        height: 48px;
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
}
</style>
      