<template>
  <div class="formBox">
    <div class="formBox_flex">
      <div>
        <div class="formBox_flex_left">
          <div class="left_name">
            <span>选择群发群聊</span>
          </div>
          <div class="search_right">
            <el-input
              placeholder="请输入群聊名称"
              v-model="queryInfo.groupName"
              class="input-with-select"
              clearable
              @change="onchange"
            >
              <el-button slot="append" @click="search(1)" type="primary">搜索</el-button>
            </el-input>
          </div>
          <div class="search_right" style="margin-top: 0px">
            所属行业：
            <el-cascader
              style="width: 250px"
              placeholder="请选择行业"
              clearable
              :props="{ label: 'industryName', value: 'industryName', children: 'children' }"
              v-model="labellist"
              :options="IndustryList"
              @change="handleChange"
            ></el-cascader>
          </div>
          <div class="num_flex">
            <div class="total_name">
              <span>共{{ total }}群聊</span>
            </div>
            <div class="all_pand">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
              <!-- <div class="trips_left">
                <i class="el-icon-warning-outline"></i>
                <span class="txt_right">每次只能选择群发500群聊</span>
              </div> -->
            </div>
            <div class="input_flex">
              <!-- <el-input v-model="input" placeholder=""></el-input>
              —
              <el-input v-model="input" placeholder=""></el-input> -->
            </div>
          </div>
          <div class="auto_wx">
            <div class="wx_item" v-for="(item, index) in allList" :key="index">
              <div>
                <el-checkbox v-model="item.checked" @change="latechange($event, item)">
                </el-checkbox>
              </div>
              <img class="wx_logo" src="../../../assets/image/wxql.png" alt="" />
              <div>
                <span>{{ item.wechatGroupName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.num"
            :page-sizes="[50, 100, 200, 500]"
            :page-size="queryInfo.size"
            layout="total, sizes, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <img class="for_img" src="../../../assets/image/zhuanhua.png" alt="" />
      <div class="formBox_flex_right">
        <el-table
          v-loading="loading"
          :data="eleList"
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="群聊名称" align="center" prop="id">
            <template v-slot="{ row }">
              <div>{{ row.wechatGroupName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="所属行业" prop="imgName" align="center">
            <template v-slot="{ row }">
              <div>
                <span> {{ row.industry }}</span
                ><span v-if="row.segmentedIndustry">-</span>
                <span v-if="row.segmentedIndustry">{{ row.segmentedIndustry }}</span>
                <span  v-if="!row.segmentedIndustry&&!row.industry ">-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="成员数量" sortable prop="imgName" align="center">
            <template v-slot="{ row }">
              <div>{{ row.memCnt }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="100px" align="center">
            <template v-slot="{ row }">
              <div v-if="row" @click="delClick(row)" class="delete_color">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
    <script>
import { wechatGroup, infoone } from '@/api/masssend.js'
import { getIndustryVo } from '@/api/visiting.js'
const defaultQueryInfo = Object.freeze({
  num: 1,
  size: 50,
  groupName: '',
  industry: '',
  segmentedIndustry: ''
})
export default {
  name: 'groupchat',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  props: {},
  components: {},
  data() {
    return {
      checkAll: false,
      loading: false,
      isIndeterminate: false,
      total: 0,
      ids: [],
      queryInfo: { ...defaultQueryInfo },
      allList: [],
      eleList: [],
      labellist: [],
      IndustryList: []
    }
  },

  created() {
    this.search()
    this.getIndustryVo()
  },

  mounted() {},
  //方法集合
  methods: {
    onchange(val) {
      if (!val) {
        this.search(1)
      }
    },
    handleChange(val) {
      if (val.length) {
        if (val.length > 1) {
          this.queryInfo.industry = val[0]
          this.queryInfo.segmentedIndustry = val[1]
        } else {
          this.queryInfo.industry = val[0]
          this.queryInfo.segmentedIndustry = ''
        }
        this.search(1)
      } else {
        this.queryInfo.industry = ''
        this.queryInfo.segmentedIndustry = ''
        this.search()
      }
    },
    async getIndustryVo() {
      const res = await getIndustryVo()
      if (res.resultCode == 200) {
        this.IndustryList = res.data
      }
    },
    async search(type) {
      const res = await wechatGroup(this.queryInfo)
      if (res.resultCode == 200) {
        let info = JSON.parse(res.message)
        this.allList = info.data.list
      
        this.allList.forEach((el) => {
          this.$set(el, 'checked', false)
        })

        this.total = info.data.total
        if (this.id && !type) {
          this.getdetais()
        } else {
          this.eleList.forEach((sourceItem) => {
            const targetItem = this.allList.find((item) => item.id === sourceItem.id)
            if (targetItem) {
              targetItem.checked = true
              this.ids.push(targetItem.id)
            }
          })
          if (this.ids.length === this.allList.length) {
            this.isIndeterminate = false
            this.checkAll = true
          } else if (this.ids.length > 0) {
            this.isIndeterminate = true
            this.checkAll = false
          }
        }
      }
    },
    async getdetais() {
      const res = await infoone({ id: this.id })
      if (res.resultCode == 200) {
        this.eleList = res.data.groupName || []
        this.eleList.forEach((sourceItem) => {
          const targetItem = this.allList.find((item) => item.id === sourceItem.id)
          if (targetItem) {
            targetItem.checked = true
            this.ids.push(targetItem.id)
          }
        })
        if (this.ids.length === this.allList.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else if (this.ids.length > 0) {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    delClick(item) {
      const index = this.ids.indexOf(item.id)

      this.eleList.splice(index, 1)
      this.ids.splice(index, 1)
      this.allList.forEach((el) => {
        if (el.id == item.id) {
          el.checked = false
        }
      })
      if (this.ids.length === this.allList.length) {
        this.isIndeterminate = false
        this.checkAll = true
      } else if (this.ids.length > 0) {
        this.isIndeterminate = true
        this.checkAll = false
      }
    },
    handleSizeChange(val) {},
    handleCurrentChange() {},

    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.allList.forEach((item) => {
          //orgCompanyId
          if (!item.checked) {
            this.ids.push(item.id)
            this.eleList.push(item)
            item.checked = true
          }
        })
      } else {
        this.allList.forEach((item) => {
          this.ids = []
          this.eleList = []
          this.checkAll = false
          item.checked = false
        })
      }

      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, row) {
      // orgCompanyId

      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        //orgCompanyId
        this.eleList.push(row)
        this.ids.push(row.id)
      } else {
        this.ids.splice(index, 1)
        this.eleList.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length === this.allList.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    }
  }
}
</script>
    <style scoped lang="less">
.formBox {
  padding: 0px 50px;
  .formBox_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .for_img {
      width: 100px;
      height: 100px;
      margin: 0px 20px;
    }
    .new_page {
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      border-top: #fafafa 1px solid;
      text-align: center;
    }
    .formBox_flex_left {
      width: 580px;
      border: 1px solid #f1f4f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      .left_name {
        width: 580px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        font-size: 16px;
        background: #f5f7fb;
      }
      .search_right {
        width: 520px;
        margin: 10px 10px;
        /deep/ .el-button {
          border: 1px solid #448aff;
          color: #fff;
          border-radius: 0;
          background-color: #448aff;
        }
      }
      .all_pand {
        margin: 0px 40px;
        display: flex;
        align-items: flex-end;
        .trips_left {
          color: #ff7f28;
          font-size: 14px;
          margin-left: 20px;
          .txt_right {
            margin-left: 5px;
          }
        }
      }
      .num_flex {
        width: 480px;
        padding: 15px 10px;
        border-top: 1px solid #f1f4f9;
        display: flex;
        align-items: center;

        .total_name {
          font-size: 16px;
          margin-left: 10px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 30px;
        }
        .input_flex {
          display: flex;
          align-items: center;
        }
      }
      .auto_wx {
        width: 100%;
        height: calc(100vh - 560px);
        overflow: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
      }
      .wx_item {
        display: flex;
        align-items: center;
        padding: 6px 25px;
        border-top: 1px solid #f1f4f9;
        .wx_logo {
          width: 28px;
          height: 28px;
          margin: 0px 30px;
        }
      }
    }
    .formBox_flex_right {
      flex: 1;
      height: calc(100vh - 300px);
      width: 880px;
      .in_table {
        height: calc(100vh - 350px);

        overflow: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
        .delete_color {
          color: #fd5469;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
    