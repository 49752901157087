<template>
  <div class="formBox">
    <div class="formBox_color">
      <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
        <el-form-item label="消息类别" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="业务内容">业务内容</el-radio>
            <el-radio label="节日祝福">节日祝福</el-radio>
            <el-radio label="宣传物料">宣传物料</el-radio>
            <el-radio label="其他">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex_time">
          <el-form-item label="发送时间" prop="sendType">
            <el-radio-group v-model="form.sendType" @change="sendTypeVal">
              <el-radio label="立即发送">立即发送</el-radio>
              <el-radio label="定时群发">定时群发</el-radio>
            </el-radio-group>
          </el-form-item>

          <div class="time_right" v-if="form.sendType == '定时群发'">
            <el-form-item label="" label-width="0px" prop="">
              <el-date-picker
             
                @change="valtime"
                v-model="timeList"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="消息内容" prop="context">
          <div class="editBarBox">
            <div class="editBar">
              <Editorbar v-model="form.context"  :isClear="isClear" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
  <script>
  import { infoone } from '@/api/masssend.js'
import Editorbar from './Editorbar.vue'
export default {
  name: 'addactivity',
  computed: {
    id() {
      return this.$route.query.id
    },
  },
  components: { Editorbar },
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        contentType: '', //消息类型
        groupName: [],
        id: '',
        sendTime: '',
        sendEndTime: '',
        sendStartTime: '',
        sendType: '立即发送',
        title: '',
        context: '',
        type: '业务内容' //消息类别
      },
      timeList: [],

      isClear: false,
      updatePwdRules: {
        type: [{ required: true, message: '请选择消息类别', trigger: 'blur' }],
        context: [{ required: true, message: '请输入消息内容', trigger: 'blur' }],
        sendType: [{ required: true, message: '请选择发送时间', trigger: 'blur' }]
      }
    }
  },
  watch: {
    // 监听 user.name 的变化
    'info.id'(newValue, oldValue) {
      
      this.form=this.info
      this.timeList.push(this.form.sendStartTime)
      this.timeList.push(this.form.sendEndTime)
    },
    
  },
  created() {
    
    if(this.id){
      this.form=this.info
      
    }
  },

  mounted() {},
  //方法集合
  methods: {
    
  async  getdetais(){
    const res=await infoone({id:this.id})
    if(res.resultCode==200){
      this.form=res.data
    }
    },
    valtime(val) {
      this.form.sendStartTime = val[0]
      this.form.sendEndTime = val[1]
    },
    sendTypeVal(val) {
      if (val == '立即发送') {
        this.form.sendEndTime = ''
        this.form.sendStartTime = ''
      }
    }
  }
}
</script>
  <style scoped lang="less">

.formBox {
  .formBox_color {
    .flex_time {
      display: flex;
      align-items: center;
      .time_right {
        margin-left: 30px;
      }
    }
    .editBarBox {
      width: 800px;
    }
  }
}
</style>
  